import { createApi } from "@reduxjs/toolkit/query/react";
import baseConfig from "./api.config";
import { REGISTER_QUERY_PARAMS } from "../../search-params";

export const userApi = createApi({
  reducerPath: "UserApi",
  baseQuery: baseConfig,
  tagTypes: ["User", "QR"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => {
        const offsetTZ = (new Date().getTimezoneOffset() / 60) * -1;
        const offsetTZleadZero = offsetTZ.toString().length === 1 ? "0" : "";
        return {
          url: "user/login",
          method: "POST",
          body,
          headers: {
            ["X-User-TZ"]:
              offsetTZ > 0
                ? `+${offsetTZleadZero + offsetTZ}`
                : offsetTZleadZero + offsetTZ,
          },
        };
      },
    }),
    getTokenByCacheKey: builder.mutation({
      query: ({ cacheKey }) => `/user/cache-auth/${cacheKey}`,
    }),
    getBalance: builder.query({
      query: () => "/user/balance",
    }),
    getUser: builder.query({
      query: () => "/user",
      providesTags: ["User"],
    }),
    signup: builder.mutation({
      query: (body) => {
        const offsetTZ = (new Date().getTimezoneOffset() / 60) * -1;
        const offsetTZleadZero = offsetTZ.toString().length === 1 ? "0" : "";
        const register_advaced_params = {};
        REGISTER_QUERY_PARAMS.forEach((key, index) => {
          const storedValue = localStorage.getItem(key);

          if (storedValue) {
            register_advaced_params[key] = storedValue;
          }
        });

        if (Object.keys(register_advaced_params).length > 0) {
          body = {
            ...body,
            ...register_advaced_params,
          };
        }

        return {
          url: `user`,
          method: "POST",
          body,
          headers: {
            ["X-User-TZ"]:
              offsetTZ > 0
                ? `+${offsetTZleadZero + offsetTZ}`
                : offsetTZleadZero + offsetTZ,
          },
        };
      },
      transformResponse: (response) => {
        REGISTER_QUERY_PARAMS.forEach((key) => {
          //Если дополнитлеьные параметры были сохранены, то удалим их после успешной регистрации
          const storedValue = localStorage.getItem(key);

          if (storedValue) {
            localStorage.removeItem(key);
          }
        });

        return response;
      },
    }),
    personalData: builder.mutation({
      query: (body) => ({
        url: `user`,
        method: "PUT",
        body,
      }),
    }),
    getCountries: builder.query({
      query: () => "list/countries",
    }),
    getRanks: builder.query({
      query: () => "list/ranks",
    }),
    getCurrencies: builder.query({
      query: () => "list/currencies",
    }),
    getRegistrationPermission: builder.query({
      query: () => ({
        url: "/user/allow",
        responseHandler: async (response) => {
          if (response.status === 200) {
            return response.text();
          }

          return await response.json();
        },
      }),
    }),
    verificationEmail: builder.mutation({
      query: ({ method = "POST", body = {} }) => {
        console.log(body);
        return {
          url: `/user/verification/email/verifycode`,
          method: method,
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    verificationPhone: builder.mutation({
      query: ({ method = "POST", body = {} }) => ({
        url: `/user/verification/phone/verifycode`,
        method: method,
        body,
      }),
      invalidatesTags: ["User"],
    }),
    sendVerificationCode: builder.mutation({
      query: ({ method = "POST", type }) => ({
        url: `/user/verification/${type}/sendcode`,
        method: method,
      }),
    }),
    getVerificationToken: builder.mutation({
      query: () => ({
        url: "/user/verification/token",
        method: "GET",
      }),
    }),
    changePassword: builder.mutation({
      query: ({ password, code, password_confirmation }) => ({
        url: "/user/password-update",
        method: "PUT",
        body: {
          password,
          code,
          password_confirmation,
        },
      }),
    }),
    requestCode: builder.mutation({
      query: () => ({
        url: `/user/request-password-update`,
        method: "POST",
      }),
    }),
    requestCodeRestore: builder.mutation({
      query: ({ phone, email }) => ({
        url: `/user/request-password-restore`,
        method: "POST",
        body: { phone, email },
      }),
    }),
    checkPasswordRestore: builder.mutation({
      query: ({ phone, email, code }) => ({
        url: `/user/check-password-restore`,
        method: "POST",
        body: { phone, email, code },
      }),
    }),
    passwordRestore: builder.mutation({
      query: ({ phone, email, code, password, password_confirm }) => ({
        url: `/user/password-restore`,
        method: "PUT",
        body: {
          phone,
          email,
          code,
          password,
          password_confirmation: password_confirm,
        },
      }),
    }),
    getQR: builder.mutation({
      query: () => "/user/2fa/qr",
      providesTags: ["QR"],
    }),
    switch2Fa: builder.mutation({
      query: (body) => ({
        method: "PATCH",
        url: "/user/2fa",
        body,
      }),
    }),
    switch2FaWithdraw: builder.mutation({
      query: () => ({
        method: "PATCH",
        url: "user/2fa/withdraw",
      }),
    }),
    linkedVKID: builder.mutation({
      query: (vk_id) => ({
        url: `/user/vk-id`,
        method: "POST",
        body: { "vk-id": vk_id },
      }),
    }),
    getQRCodeTGLinked: builder.mutation({
      query: () => ({
        url: `/user/tg-key`,
        method: "GET",
      }),
    }),
    getQRCodePhoneLinked: builder.mutation({
      query: () => ({
        url: `/user/phone-link-key`,
        method: "GET",
      }),
    }),
    selfBlock: builder.mutation({
      query: ({ days }) => ({
        url: `/user/self-block`,
        method: "POST",
        body: { days },
      }),
    }),
    getSpinSlots: builder.mutation({
      query: () => ({
        url: `/lucky-spin`,
        method: "GET",
      }),
    }),
    getSpinLastResult: builder.mutation({
      query: () => ({
        url: `/lucky-spin/result`,
        method: "GET",
      }),
    }),
    getSpinAvailable: builder.mutation({
      query: () => ({
        url: `/lucky-spin/available`,
        method: "GET",
      }),
    }),
    spinResultViewed: builder.mutation({
      query: ({ id }) => ({
        url: `/lucky-spin/result/${id}`,
        method: "POST",
      }),
    }),
    spinWheel: builder.mutation({
      query: () => ({
        url: `/lucky-spin/spin`,
        method: "POST",
      }),
    }),
    getRefreshAccessToken: builder.mutation({
      query: () => ({
        url: `/user/refresh`,
        method: "GET",
      }),
    }),
    userMark: builder.mutation({
      query: (body) => ({
        url: `/user-mark`,
        method: "POST",
        body,
      }),
    }),
    getUserNotification: builder.query({
      query: (user_id) => `/notification`,
    }),
    deleteUserNotification: builder.mutation({
      query: ({ user_id, notification_id }) => ({
        url: `/notification/${notification_id}`,
        method: "DELETE",
      }),
    }),
    readUserNotification: builder.mutation({
      query: ({ user_id, notification_id }) => ({
        url: `/notification/${notification_id}`,
        method: "PATCH",
      }),
    }),
    getPokerOpenSession: builder.query({
      query: () => ({
        url: `/poker/event-bet/session`,
        method: "GET",
      }),
    }),
    getPokerBalance: builder.query({
      query: () => ({
        url: `/transfer/event-bet/balance`,
        method: "GET",
      }),
    }),
    userTransferDepositPoker: builder.mutation({
      query: (amount) => ({
        url: `transfer/event-bet/deposit`,
        method: "Post",
        body: { amount: amount },
      }),
    }),
    userTransferWithdrawPoker: builder.mutation({
      query: (amount) => ({
        url: `transfer/event-bet/withdraw`,
        method: "Post",
        body: { amount: amount },
      }),
    }),
  }),
});

export const {
  useSwitch2FaMutation,
  useSwitch2FaWithdrawMutation,
  useGetQRMutation,
  useChangePasswordMutation,
  useLoginMutation,
  useRequestCodeMutation,
  useGetCurrenciesQuery,
  useGetBalanceQuery,
  usePersonalDataMutation,
  useSignupMutation,
  useGetUserQuery,
  useGetCountriesQuery,
  useGetRanksQuery,
  useGetRegistrationPermissionQuery,
  useVerificationEmailMutation,
  useVerificationPhoneMutation,
  useGetVerificationTokenMutation,
  useSendVerificationCodeMutation,
  useRequestCodeRestoreMutation,
  useCheckPasswordRestoreMutation,
  usePasswordRestoreMutation,
  useLinkedVKIDMutation,
  useGetQRCodeTGLinkedMutation,
  useGetSpinSlotsMutation,
  useGetSpinLastResultMutation,
  useGetSpinAvailableMutation,
  useSpinWheelMutation,
  useSpinResultViewedMutation,
  useGetQRCodePhoneLinkedMutation,
  useSelfBlockMutation,
  useGetRefreshAccessTokenMutation,
  useUserMarkMutation,
  useGetUserNotificationQuery,
  useDeleteUserNotificationMutation,
  useReadUserNotificationMutation,
  useGetTokenByCacheKeyMutation,
  useGetPokerOpenSessionQuery,
  useGetPokerBalanceQuery,
  useUserTransferDepositPokerMutation,
  useUserTransferWithdrawPokerMutation,
} = userApi;
